import {
  searchPenByFLM,
  getPensionDiedOrDisableStaffs,
  getPensionOtherStaffData,
  upsertPensionAccounting,
  searchPenByTFLM,
  getPensionsChildren,
  getClosedPensions,
  getRecomendPensions,
  getAllPensions,
} from "../../globalStateQueries/PesionQueries";

export const PensionExtraReducers = (builder) => {
  // upsert pension acounting
  builder
    .addCase(upsertPensionAccounting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertPensionAccounting.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertPensionAccounting.rejected, (state, action) => {
      state.loading = false;
    })

    // searchPenByFLM
    .addCase(searchPenByFLM.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(searchPenByFLM.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload?.data)) {
        state.pensioners = action.payload.data;
      } else {
        state.pensioners = [];
      }
      if (action.payload.openModal) {
        state.showPensionData = { open: true, details: null };
      }
    })
    .addCase(searchPenByFLM.rejected, (state, action) => {
      state.loading = false;
    })

    // getRecomendPensions
    .addCase(getRecomendPensions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRecomendPensions.fulfilled, (state, action) => {
      state.loading = false;
      state.recommendPensions = action.payload;
    })
    .addCase(getRecomendPensions.rejected, (state, action) => {
      state.loading = false;
    })

    // searchPenByTFLM
    .addCase(searchPenByTFLM.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(searchPenByTFLM.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) {
        state.pensioners = action.payload;
      } else {
        state.pensioners = [];
      }
      state.showPensionData = { open: true, details: null };
    })
    .addCase(searchPenByTFLM.rejected, (state, action) => {
      state.loading = false;
    })

    // searchPenByTFLM
    .addCase(getClosedPensions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getClosedPensions.fulfilled, (state, action) => {
      state.loading = false;
      state.closedPensions = action.payload;
    })
    .addCase(getClosedPensions.rejected, (state, action) => {
      state.loading = false;
    })

    //getPensionDiedOrDisableStaffs
    .addCase(getPensionDiedOrDisableStaffs.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionDiedOrDisableStaffs.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionDiedOrDisableStaffs = action.payload;
    })
    .addCase(getPensionDiedOrDisableStaffs.rejected, (state, action) => {
      state.loading = false;
    })

    //getPensionOtherStaffData
    .addCase(getPensionOtherStaffData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionOtherStaffData.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionOtherStaffData = action.payload;
    })
    .addCase(getPensionOtherStaffData.rejected, (state, action) => {
      state.loading = false;
    })

    //getPensionsChildren
    .addCase(getPensionsChildren.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionsChildren.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionsChildrens = action.payload;
    })
    .addCase(getPensionsChildren.rejected, (state, action) => {
      state.loading = false;
    })

    //getAllPensions
    .addCase(getAllPensions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllPensions.fulfilled, (state, action) => {
      state.loading = false;
      state.allPensions = action.payload;
    })
    .addCase(getAllPensions.rejected, (state, action) => {
      state.loading = false;
    });
};
