import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  documentVerify,
  getFileById,
} from "../../redux/reducers/globalStateQueries/StorageQueries";
import { useDispatch, useSelector } from "react-redux";
import PDFViewer from "./PDFViewer";
import { getRoleOption } from "../../redux/reducers/globalStateQueries/AdminQueries";
import { useTranslation } from "react-i18next";
import { FiUser, FiBriefcase, FiClock } from "react-icons/fi";
import { LuBuilding2 } from "react-icons/lu";
import { getUsers } from "../../redux/reducers/globalStateQueries/CommonQueries";

export default function ScannerQRdocument() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [matchedUsers, setMatchedUsers] = useState([]);
  const documentPDf = useSelector(({ globalState }) => globalState.documentPDf);
  const roleOption = useSelector(({ globalState }) => globalState.roleOption);
  const documentVerifyName = useSelector(
    ({ globalState }) => globalState.documentVerifyName
  );
  const users = useSelector(({ globalState }) => globalState.users);

  useEffect(() => {
    if (Array.isArray(documentVerifyName) && documentVerifyName?.length) {
      const userMap = users?.reduce((map, user) => {
        if (user?.id) {
          map[user.id] = user;
        }
        return map;
      }, {});
      const matchedUsers = documentVerifyName?.map((doc) => {
        const user = userMap?.[doc?.user_id];
        return user ? user : null;
      });
      setMatchedUsers(matchedUsers);
    }
  }, [documentVerifyName, users]);

  useEffect(() => {
    dispatch(getFileById({ file_id: id, download: false }));
    dispatch(documentVerify({ file_id: id }));
    dispatch(getUsers());
    dispatch(getRoleOption());
  }, [id, dispatch]);

  console.log("====================================");
  console.log(
    "rol",
    roleOption?.find(
      (el) =>
        el?.id ===
        users?.find((el) => el?.id === documentVerifyName?.[0]?.user_id)
          ?.role_id
    )?.role_name?.[t("language")]
  );
  console.log("matchedUsers", matchedUsers);
  console.log("documentVerifyName", documentVerifyName);
  console.log("documentPDf", documentPDf);
  console.log("====================================");

  return (
    <>
      <div className="pt-[20px] px-[20px] min-h-[100vh]">
        {documentPDf?.size && <PDFViewer pdfBlob={documentPDf} />}
        <div className="flex flex-wrap gap-[15px] mt-[15px]">
          {/* <label htmlFor="signature">{t("document.signature")}</label> */}

          {users?.find((el) => el?.id === documentVerifyName?.[0]?.user_id)
            ?.fullname
            ? [...matchedUsers]?.map((el, i) => (
                <div
                  className="p-5 mb-5 bg-white rounded-lg shadow-md min-w-[400px]"
                  key={i}
                >
                  {/* Заголовок */}
                  <div className="text-lg font-bold text-gray-700 mb-3">
                    {t("document.signature")}: №{i + 1}
                  </div>

                  {/* ФИО */}
                  <div className="flex items-center text-base text-gray-600 mb-2">
                    <FiUser className="mr-2 text-xl" />
                    <span>{el?.fullname}</span>
                  </div>

                  {/* Роль */}
                  <div className="flex items-center text-base text-gray-600 mb-2">
                    <LuBuilding2 className="mr-2 text-xl" />
                    <span>
                      {
                        roleOption?.find((prev) => prev?.id === el?.role_id)
                          ?.role_name?.[t("language")]
                      }
                    </span>
                  </div>

                  {/* Должность */}
                  <div className="flex items-center text-base text-gray-600 mb-2">
                    <FiBriefcase className="mr-2 text-xl" />
                    <span>{el?.jobtitle}</span>
                  </div>

                  {/* Дата */}
                  <div className="flex items-center text-base text-gray-600">
                    <FiClock className="mr-2 text-xl" />
                    <span>
                      {documentVerifyName?.[i]?.signed_date
                        ?.split("T")[0]
                        ?.split("-")
                        ?.reverse()
                        ?.join(".")}
                    </span>
                  </div>
                </div>
              ))
            : t("document.notSignature")}
        </div>
      </div>
    </>
  );
}
