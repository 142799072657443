import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import Loader from "./components/loader/Loader";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <BrowserRouter>
        <FluentProvider theme={teamsLightTheme}>
          <App />
        </FluentProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
