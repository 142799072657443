import {
  setDepartmentId,
  setSelectedStaffs,
  setStaff,
  setStaffs,
  setStaffValidationState,
} from "../redux/reducers/globalState";
import { message } from "antd";
import { queriesMessage } from "../constanst/const";
import {
  getAdditionalPaymentsOption,
  getBaseIndicator,
  getCategory,
  getCountries,
  getDeceasedStaffGroups,
  getDepartmentOption,
  getDisabledStaffGroups,
  getDisciplinaryAction,
  getDismissalTypes,
  getDocumentType,
  getGenderOption,
  getJobtitleOption,
  getMarkerOption,
  getNamenclatura,
  getPensionAwards,
  getPensionTypes,
  getRankOption,
  getRegion,
  getRetentionsOption,
  getStaffFinancialAid,
  getStaffStatus,
  getTripDailyPayOptions,
  getUnits,
  getVacationOption,
} from "../redux/reducers/globalStateQueries/CommonQueries";
import {
  getJobtitleAmount,
  getRankbonus,
  getRoleOption,
  getUnifiedChartOfAccounts,
} from "../redux/reducers/globalStateQueries/AdminQueries";
import i18n from "i18next";
import toast from "react-hot-toast";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function diramToSomoni(diram) {
  if (!(diram / 100)) return "- - -";
  else {
    let somoni = diram / 100;
    somoni = "" + somoni;
    // somoni = `${somoni?.split(".")?.[0]}.${somoni?.split(".")?.[1]?.[0] || ""}${
    //   somoni?.split(".")?.[1]
    // }`;
    return somoni;
  }
}

export function errorHandler(status, response, request) {
  const errorMessageMap = {
    401: "error.unauthorized",
    403: "error.unauthorizedUser",
    404: "error.notFound",
    409: "error.queryError",
    422: "error.unprocessableEntity",
    500: "error.serverError",
    502: "error.badGateway",
    504: "error.gatewayTimeout",
    default: "error.default",
  };

  // Используем i18n для перевода
  const userMessage = i18n.t(
    errorMessageMap[status] || errorMessageMap.default
  );

  // Показываем сообщение пользователю
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter duration-20" : "animate-leave duration-20"
      } max-w-md w-full mx-72 bg-white shadow-lg rounded-lg pointer-events-auto flex flex-wrap justify-center items-center ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-full p-4">
        <div className="flex justify-center items-center">
          <div className="mx-3 flex-1">
            <p className="text-sm font-medium text-red-500">{userMessage}</p>
            <details>
              <summary className="flex cursor-pointer text-blue-600">
                <p className="mt-1 text-sm text-blue-600 opacity-55">
                  {i18n.t("error.details")}
                </p>
              </summary>
              <p className="w-[250px] mx-2 text-xs">
                {JSON.stringify(
                  {
                    status: response?.status,
                    data: response?.data,
                    request: request?.responseURL,
                  },
                  null,
                  2
                )}
              </p>
            </details>
          </div>
        </div>
      </div>
      <div className=" " onClick={() => toast.dismiss(t.id)}>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full p-5 flex items-center justify-center text-sm font-medium  text-indigo-600 hover:text-indigo-500 focus:text-green-500"
        >
          {i18n.t("error.closeInfo")}
        </button>
      </div>
    </div>
  ));
}

// currentYear
let currentYear = new Date().getFullYear();

export const setCurrentYear = (year) => {
  currentYear = year;
};

export const getCurrentYear = () => currentYear;

export const resetLeftMenu = (dispatch, doNotClear = []) => {
  if (!doNotClear.includes(1)) {
    dispatch(setStaff({}));
    dispatch(setSelectedStaffs([]));
  }
  if (!doNotClear.includes(2)) {
    dispatch(setStaffs([]));
  }
  if (!doNotClear.includes(3)) {
    dispatch(setDepartmentId(null));
  }
  if (!doNotClear.includes(4)) {
    dispatch(setStaffValidationState({ erro: false, label: null }));
  }
};

export function handleBinaryData(
  arrayBuffer,
  filename = "file.xlsx",
  type = "application/octet-stream"
) {
  // Process the ArrayBuffer here
  // For example, converting it to a Blob for further use
  const blob = new Blob([arrayBuffer], { type });

  // Optionally create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // You can now use the Blob URL to download or display the file
  // Example: create a download link
  const a = document.createElement("a");
  a.href = url;
  a.download = filename; // Specify a file name
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up
  URL.revokeObjectURL(url);
}

export const showDate = (date) => {
  if (date) {
    let yyyy = new Date(date).getFullYear() + "";
    let mm = new Date(date).getMonth() + 1 + "";
    let dd = new Date(date).getDate() + "";
    if (mm.length === 1) mm = "0" + mm;
    if (dd.length === 1) dd = "0" + dd;
    return `${yyyy}-${mm}-${dd}`;
  }
  return "- - -";
};

export const putZero = (num) => {
  if (num < 10) return `0${num}`;
  else return `${num}`;
};

export const putDepartmentChildrens = (department) => {
  let newDepartmentParents = [];
  let newDepartmentChildrens = [];
  department?.map((el) => {
    if (!el.parent_id) {
      newDepartmentParents.push(el);
    } else {
      newDepartmentChildrens.push(el);
    }
  });
  newDepartmentParents = newDepartmentParents.map((el) => {
    if (newDepartmentChildrens.find((e) => e.parent_id === el.id)) {
      return {
        ...el,
        children: newDepartmentChildrens.filter((e) => e.parent_id === el.id),
      };
    }
    return el;
  });
  return newDepartmentParents;
};

export const toBase64 = (file) =>
  new Promise((resolve, rejective) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => rejective(error);
  });

export const converterArrayToObject = (arr, defaultObj) => {
  const Obj = { ...defaultObj };
  if (Array.isArray(arr)) {
    arr?.map((el) => {
      Obj[el.id] = { ...el };
      return el;
    });
    return { ...Obj };
  }
  return Obj;
};

export const phoneNumberFormater = (phoneNumber = "", reverse = false) => {
  if (reverse && phoneNumber?.length === 9) {
    return `${phoneNumber?.slice(0, 3)}-${phoneNumber?.slice(
      3,
      5
    )}-${phoneNumber?.slice(5, 9)}`;
  } else {
    return phoneNumber?.split("-")?.join("")?.slice(0, 9) || "";
  }
};

export const staffsPhoneNumberFormater = (staffs = [], reverse = false) => {
  return (
    staffs?.map((el) => {
      const phone = phoneNumberFormater(el.phone, reverse);
      return { ...el, phone };
    }) || []
  );
};

export const dateFormater = (date, part = null, all = false) => {
  const newDate = date.split("T");
  if (part) {
    return part === 1 ? newDate[0] : all ? newDate[1] : newDate[1].slice(0, 5);
  }
  if (all) {
    return `${newDate[0]} ${newDate[1]}`;
  }
  return `${newDate[0]} ${newDate[1].slice(0, 5)}`;
};

export const sortItemsWithNumber = (itemName, prop = "type.tj") => {
  return itemName
    ?.slice()
    ?.sort((a, b) => {
      const getNestedProp = (obj, path) =>
        path.split(".").reduce((acc, key) => acc?.[key], obj) ?? "";

      const nameA = getNestedProp(a, prop);
      const nameB = getNestedProp(b, prop);

      if (a.disabled !== b.disabled) return b.disabled ? -1 : 1;
      return nameB - nameA;
    })

    ?.map((el, index) => ({
      ...el,
      num: index + 1,
      key: index + 1,
      amount: el.amount / 100,
    }));
};

export const sortItemsByAlphabet = (itemName, prop = "type.tj") => {
  return itemName
    ?.slice()
    ?.sort((a, b) => {
      const getNestedProp = (obj, path) =>
        path.split(".").reduce((acc, key) => acc?.[key], obj) ?? "";

      const nameA = getNestedProp(a, prop);
      const nameB = getNestedProp(b, prop);

      if (a.disabled !== b.disabled) return b.disabled ? -1 : 1;

      const isLetterA = /^[А-Яа-яӢӣҚқҒғҶҷҲҳЎў]/.test(nameA);
      const isLetterB = /^[А-Яа-яӢӣҚқҒғҶҷҲҳЎў]/.test(nameB);

      if (isLetterA && !isLetterB) return -1;
      if (!isLetterA && isLetterB) return 1;

      return nameA.localeCompare(nameB, "tg");
    })
    ?.map((el, index) => ({
      ...el,
      num: index + 1,
      key: index + 1,
    }));
};

export const ReferenceBookDispatch = (dispatch, item) => {
  const actions = {
    2: () => dispatch(getRoleOption()),
    3: () => dispatch(getDepartmentOption({ limit: 200, offset: 0 })),
    4: () => dispatch(getMarkerOption()),
    5: () => dispatch(getRankOption()),
    6: () => dispatch(getGenderOption()),
    7: () => dispatch(getRetentionsOption()),
    8: () => dispatch(getAdditionalPaymentsOption()),
    9: () => dispatch(getStaffStatus()),
    10: () => dispatch(getDismissalTypes()),
    11: () => dispatch(getDisciplinaryAction()),
    12: () => dispatch(getPensionTypes()),
    14: () => dispatch(getDeceasedStaffGroups()),
    15: () => dispatch(getDisabledStaffGroups()),
    16: () => dispatch(getStaffFinancialAid()),
    17: () => dispatch(getVacationOption()),
    18: () => dispatch(getTripDailyPayOptions()),
    20: () => dispatch(getCountries()),
    21: () => dispatch(getRegion()),
    22: () => dispatch(getPensionAwards()),
    23: () => {
      dispatch(getRankbonus({}));
      dispatch(getRankOption());
    },
    24: () => dispatch(getJobtitleAmount({})),
    25: () => {
      dispatch(getUnits());
      dispatch(getNamenclatura());
      dispatch(getCategory());
    },
    26: () => dispatch(getUnits()),
    27: () => dispatch(getBaseIndicator()),
    28: () => dispatch(getUnifiedChartOfAccounts({})),
  };

  if (actions[item]) {
    actions[item]();
  }

  if (["13", "23", "24"].includes(item)) {
    dispatch(getDocumentType({}));
  }

  if (["19", "24"].includes(item)) {
    dispatch(getJobtitleOption());
  }
};
