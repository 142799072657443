import {
  getTransactionSalaryIntermediate,
  postTransactionSalaryIntermediateAccept,
  postTransactionSalaryIntermediateDecline,
  createStaffRetention,
  createStaffAdditionalPayment,
  getAdditionalPayments,
  getRetentions,
  getTransactionSalaryIntermediateBankData,
  getMoneyCertificate,
} from "../../globalStateQueries/PaymentQueries";

export const PaymentExtraReducers = (builder) => {
  // get transaction salary intermediate
  builder
    .addCase(getTransactionSalaryIntermediate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getTransactionSalaryIntermediate.fulfilled, (state, action) => {
      state.loading = false;
      state.taransactionSalaryIntermediate = action.payload;
    })
    .addCase(getTransactionSalaryIntermediate.rejected, (state, action) => {
      state.loading = false;
    })

    // post transaction salary intermediate accept
    .addCase(
      postTransactionSalaryIntermediateAccept.pending,
      (state, action) => {
        state.loading = true;
      }
    )
    .addCase(
      postTransactionSalaryIntermediateAccept.fulfilled,
      (state, action) => {
        state.loading = false;
        state.taransactionSalaryIntermediateDoc = action.payload;
      }
    )
    .addCase(
      postTransactionSalaryIntermediateAccept.rejected,
      (state, action) => {
        state.loading = false;
      }
    )

    // post transaction salary intermediate accept
    .addCase(
      getTransactionSalaryIntermediateBankData.pending,
      (state, action) => {
        state.loading = true;
      }
    )
    .addCase(
      getTransactionSalaryIntermediateBankData.fulfilled,
      (state, action) => {
        state.loading = false;
        state.taransactionSalaryIntermediateBankData = action.payload;
      }
    )
    .addCase(
      getTransactionSalaryIntermediateBankData.rejected,
      (state, action) => {
        state.loading = false;
      }
    )

    // post transaction salary intermediate decline
    .addCase(
      postTransactionSalaryIntermediateDecline.pending,
      (state, action) => {
        state.loading = true;
      }
    )
    .addCase(
      postTransactionSalaryIntermediateDecline.fulfilled,
      (state, action) => {
        state.loading = false;
        state.taransactionSalaryIntermediate = [];
        state.taransactionSalaryIntermediateDoc = null;
      }
    )
    .addCase(
      postTransactionSalaryIntermediateDecline.rejected,
      (state, action) => {
        state.loading = false;
      }
    )

    // create staff additional payment
    .addCase(createStaffAdditionalPayment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffAdditionalPayment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffAdditionalPayment.rejected, (state, action) => {
      state.loading = false;
    })

    // get additional payments
    .addCase(getAdditionalPayments.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAdditionalPayments.fulfilled, (state, action) => {
      state.loading = false;
      state.additionalPayments = action.payload;
    })
    .addCase(getAdditionalPayments.rejected, (state, action) => {
      state.loading = false;
    })

    // create staff retetion
    .addCase(createStaffRetention.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffRetention.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffRetention.rejected, (state, action) => {
      state.loading = false;
    })

    // get retetions
    .addCase(getRetentions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRetentions.fulfilled, (state, action) => {
      state.loading = false;
      state.retentions = action.payload;
    })
    .addCase(getRetentions.rejected, (state, action) => {
      state.loading = false;
    })

    // get money sertification
    .addCase(getMoneyCertificate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getMoneyCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getMoneyCertificate.rejected, (state, action) => {
      state.loading = false;
    });
};
