//////////////////////       Swagger PENSION          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";

// pension #########################

// upsert pension acounting
export const upsertPensionAccounting = createAsyncThunk(
  "upsertPensionAccounting",
  async (body) => {
    try {
      const { data } = await axiosRequest.post("pension/v1/pension", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// search pension by flm
export const searchPenByFLM = createAsyncThunk(
  "searchPenByFLM",
  async ({ user_id, firstname, lastname, middlename, openModal = true }) => {
    try {
      const params = new URLSearchParams();
      params.append("user_id", user_id);
      if (firstname) params.append("_firstname", firstname);
      if (lastname) params.append("_lastname", lastname);
      if (middlename) params.append("_middlename", middlename);
      const { data } = await axiosRequest.get(`pension/v1/pension?${params}`);
      return { data, openModal };
    } catch (error) {
      console.log("error", error);
    }
  }
);

// getRecomendPensions
export const getRecomendPensions = createAsyncThunk(
  "getRecomendPensions",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `pension/v1/pension/recommend`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// search pension by flm
export const searchPenByTFLM = createAsyncThunk(
  "searchPenByTFLM",
  async ({ type_id, firstname, lastname, middlename }) => {
    try {
      const params = new URLSearchParams();
      params.append("type_id", type_id);
      if (firstname) params.append("_firstname", firstname);
      if (lastname) params.append("_lastname", lastname);
      if (middlename) params.append("_middlename", middlename);
      const { data } = await axiosRequest.get(
        `pension/v1/pension/search?${params}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// search pension by flm
export const getClosedPensions = createAsyncThunk(
  "getClosedPensions",
  async ({ limit, offset, firstname, lastname, middlename }) => {
    try {
      const params = new URLSearchParams();
      if (firstname) params.append("limit", limit);
      if (firstname) params.append("offset", offset);
      if (firstname) params.append("firstname", firstname);
      if (lastname) params.append("lastname", lastname);
      if (middlename) params.append("middlename", middlename);
      const { data } = await axiosRequest.get(
        `pension/v1/pension/closed_pensions?${params}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// getPensionDiedOrDisableStaffs
export const getPensionDiedOrDisableStaffs = createAsyncThunk(
  "getPensionDiedOrDisableStaffs",
  async ({ type_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/died_or_disabled_staffs?type_id=${type_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getPensionOtherStaffData = createAsyncThunk(
  "getPensionOtherStaffData",
  async ({ staff_id, department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/other_staff_data?staff_id=${staff_id}&department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getPensionsChildren = createAsyncThunk(
  "getPensionsChildren",
  async ({ staff_id, type_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/search/children?staff_id=${staff_id}&type_id=${type_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getAllPensions = createAsyncThunk(
  "getAllPensions",
  async ({ offset, limit }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/all?limit=${limit}&offset=${offset}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
