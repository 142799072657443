import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalState from "../reducers/globalState";
import MFLSS from "../reducers/MFLSS";
// import globalRequest from "../reducers/globalRequest";

const rootReducer = combineReducers({
    globalState: globalState,
    MFLSS: MFLSS,
    // [globalRequest.reducerPath]: globalRequest.reducer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['MFLSS'],
    // blacklist: ['globalState', 'globalRequest'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(globalRequest.middleware),
});

export const persistor = persistStore(store);
export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import globalState from "../reducers/globalState";

// export const store = configureStore({
//   reducer: { globalState: globalState },
// });
