import axios from "axios";
import { destroyToken, saveToken } from "./Token";
import { encruption } from "./encruption";
import { errorHandler } from "./globalFunction";

const API_URL = process.env.REACT_APP_API_URL;
const TIMEOUT = 10000;

// Create an Axios instance
const axiosRequest = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    common: {
      "X-APP-TOKEN": encruption(),
    },
    get: {
      "X-APP-TOKEN": encruption(),
    },
    post: {
      "X-APP-TOKEN": encruption(),
    },
    put: {
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      "X-APP-TOKEN": encruption(),
    },
    patch: {
      "X-APP-TOKEN": encruption(),
    },
  },
});

// Request interceptor to add Authorization header
axiosRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle errors and token refresh
axiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response, request } = error;
    const status = response?.status || "unknown";
    const originalRequest = config;

    errorHandler(status, response, request);

    // Обработка логики токена
    if (status === 401) {
      try {
        const refreshResponse = await axios.get(
          `${API_URL}auth/v1/refresh_token`,
          {
            headers: {
              "X-APP-TOKEN": encruption(),
            },
          }
        );
        if (refreshResponse.status === 401) {
          destroyToken();
          window.location.href = "/login";
          return Promise.reject(new Error("Unauthorized"));
        }
        saveToken(response.data.access_token);
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export { axiosRequest };
