import {
  businessTripApprove,
  businessTripDownloadCertificate,
  cashDownloadDocument4,
  cashDownloadRKO,
  getBusinessTripCahs,
  getBusinessTripCahsFileById,
  getCashAccountPlan,
  getCashArrivalOrder,
  getCashBook,
  getCashSER,
  getCashDocument4ApprovedOnes,
  getCashMemorialWarrant1,
  getCashMemorialWarrant8,
  getUpdateAgreements,
  postCashAccountPlan,
  postCashArrivalOrder,
  postCashDocument4,
  postUpdateAgreements,
  updateBusinessTripCahsApprove,
  putCashSER,
  getApprovedSER,
  getRejectedSER,
  getWarehouseByNumberName,
  getWarehouseDeffectsByStaffIdOrNameId,
  postWarehouseDefects,
  getIncomingByWnumCid,
  postIncoming,
  getAccountingOutgoingData,
  postAccountingOutgoingData,
  getAccountingOutgoingSearch,
  postWarehouseStat,
  getWarehouseDisposal,
  postBid,
  getBudgetDestribution,
  postBudgetDestribution,
} from "../../globalStateQueries/AccountingQueries";

export const AccountingExtraReducers = (builder) => {
  // businessTrip download certificate
  builder
    .addCase(businessTripDownloadCertificate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(businessTripDownloadCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(businessTripDownloadCertificate.rejected, (state, action) => {
      state.loading = false;
    })

    // businessTrip approve
    .addCase(businessTripApprove.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(businessTripApprove.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(businessTripApprove.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash salary earley receipt
    .addCase(getCashSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashSER.fulfilled, (state, action) => {
      state.loading = false;
      state.cashSER = action.payload;
    })
    .addCase(getCashSER.rejected, (state, action) => {
      state.loading = false;
    })

    // put cash salary earley receipt
    .addCase(putCashSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(putCashSER.fulfilled, (state, action) => {
      state.loading = false;
      state.cashSER = [];
    })
    .addCase(putCashSER.rejected, (state, action) => {
      state.loading = false;
    })

    // get approved salary early recipt
    .addCase(getApprovedSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getApprovedSER.fulfilled, (state, action) => {
      state.loading = false;
      state.approvedSER = action.payload;
    })
    .addCase(getApprovedSER.rejected, (state, action) => {
      state.loading = false;
    })

    // get rejected salary early recipt
    .addCase(getRejectedSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRejectedSER.fulfilled, (state, action) => {
      state.loading = false;
      state.rejectedSER = action.payload;
    })
    .addCase(getRejectedSER.rejected, (state, action) => {
      state.loading = false;
    })

    // post cash document 4
    .addCase(postCashDocument4.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashDocument4.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashDocument4.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash document 4 approved ones
    .addCase(getCashDocument4ApprovedOnes.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashDocument4ApprovedOnes.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashDocument4ApprovedOnes.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash book
    .addCase(getCashBook.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashBook.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashBook.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash memorial warrant1
    .addCase(getCashMemorialWarrant1.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashMemorialWarrant1.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashMemorialWarrant1.rejected, (state, action) => {
      state.loading = false;
    })

    // cash download document 4
    .addCase(cashDownloadDocument4.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(cashDownloadDocument4.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(cashDownloadDocument4.rejected, (state, action) => {
      state.loading = false;
    })

    // cash download rko
    .addCase(cashDownloadRKO.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(cashDownloadRKO.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(cashDownloadRKO.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash account plan
    .addCase(getCashAccountPlan.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashAccountPlan.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashAccountPlan.rejected, (state, action) => {
      state.loading = false;
    })

    // post cash account plan
    .addCase(postCashAccountPlan.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashAccountPlan.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashAccountPlan.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash memorial warrant8
    .addCase(getCashMemorialWarrant8.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashMemorialWarrant8.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashMemorialWarrant8.rejected, (state, action) => {
      state.loading = false;
    })

    // post cash arrival_order
    .addCase(postCashArrivalOrder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashArrivalOrder.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashArrivalOrder.rejected, (state, action) => {
      state.loading = false;
    })

    // get cash arrival_order
    .addCase(getCashArrivalOrder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashArrivalOrder.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashArrivalOrder.rejected, (state, action) => {
      state.loading = false;
    })

    // post postUpdateAgreements
    .addCase(postUpdateAgreements.pending, (state) => {
      state.loading = true;
    })
    .addCase(postUpdateAgreements.fulfilled, (state, action) => {
      state.loading = false;
      state.agreements = action.payload;
    })
    .addCase(postUpdateAgreements.rejected, (state, action) => {
      state.loading = false;
    })

    // get getUpdateAgreements
    .addCase(getUpdateAgreements.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getUpdateAgreements.fulfilled, (state, action) => {
      state.loading = false;
      state.accountingAgreementsData = action.payload;
    })
    .addCase(getUpdateAgreements.rejected, (state, action) => {
      state.loading = false;
    })
    // getBusinessTripCahs

    .addCase(getBusinessTripCahs.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getBusinessTripCahs.fulfilled, (state, action) => {
      state.BusinessTripCahs = action.payload;
      state.loading = false;
    })
    .addCase(getBusinessTripCahs.rejected, (state, action) => {
      console.error("Failed to fetch business trip data:", action.payload);
    })

    // getWarehouseDeffectsByStaffIdOrNameId
    .addCase(getWarehouseDeffectsByStaffIdOrNameId.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(
      getWarehouseDeffectsByStaffIdOrNameId.fulfilled,
      (state, action) => {
        state.staffWarehouseDeffects = action.payload;
        state.loading = false;
      }
    )
    .addCase(
      getWarehouseDeffectsByStaffIdOrNameId.rejected,
      (state, action) => {
        console.error("Failed to fetch business trip data:", action.payload);
        state.loading = false;
      }
    )

    // postWarehouseDefects
    .addCase(postWarehouseDefects.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postWarehouseDefects.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postWarehouseDefects.rejected, (state, action) => {
      state.loading = false;
    })

    // getAccountingOutgoingData
    .addCase(getAccountingOutgoingData.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAccountingOutgoingData.fulfilled, (state, action) => {
      state.loading = false;
      state.accountingOutgoingData = action.payload;
    })
    .addCase(getAccountingOutgoingData.rejected, (state, action) => {
      state.accountingOutgoingData = [];
      state.loading = false;
    })
    // getAccountingOutgoingSearch
    .addCase(getAccountingOutgoingSearch.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAccountingOutgoingSearch.fulfilled, (state, action) => {
      state.accountingOutgoingSearch = action.payload;
      state.loading = false;
    })
    .addCase(getAccountingOutgoingSearch.rejected, (state, action) => {
      state.accountingOutgoingSearch = 0;
      console.error("Failed to fetch business trip data:", action.payload);
      state.loading = false;
    })
    // postAccountingOutgoingData
    .addCase(postAccountingOutgoingData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postAccountingOutgoingData.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postAccountingOutgoingData.rejected, (state, action) => {
      state.loading = false;
    })

    // getBusinessTripCahsFileById
    .addCase(getBusinessTripCahsFileById.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getBusinessTripCahsFileById.fulfilled, (state, action) => {
      state.getBusinessTripCahsFileById = action.payload;
      state.loading = false;
    })
    .addCase(getBusinessTripCahsFileById.rejected, (state, action) => {
      console.error("Failed to fetch business trip data:", action.payload);
    })
    // Approve
    .addCase(updateBusinessTripCahsApprove.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateBusinessTripCahsApprove.fulfilled, (state, action) => {
      // Вы можете обновить состояние, если нужно
      console.log("Статус обновлен:", action.payload);
    })
    .addCase(updateBusinessTripCahsApprove.rejected, (state, action) => {
      state.loading = false;
    })

    // getWarehouseByNumberName
    .addCase(getWarehouseByNumberName.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getWarehouseByNumberName.fulfilled, (state, action) => {
      state.loading = false;
      state.WarehouseSearch = action.payload;
    })
    .addCase(getWarehouseByNumberName.rejected, (state, action) => {
      state.loading = false;
    })

    // getIncomingByNumberName
    .addCase(getIncomingByWnumCid.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getIncomingByWnumCid.fulfilled, (state, action) => {
      state.loading = false;
      state.IncomingSearch = action.payload;
    })
    .addCase(getIncomingByWnumCid.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // getIncomingByNumberName
    .addCase(postIncoming.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postIncoming.fulfilled, (state, action) => {
      state.loading = false;
      state.IncomingSearch = action.payload;
    })
    .addCase(postIncoming.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // postWarehoouseStat
    .addCase(postWarehouseStat.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postWarehouseStat.fulfilled, (state, action) => {
      state.loading = false;
      state.WareHouseStat = action.payload;
    })
    .addCase(postWarehouseStat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // postWarehoouseStat
    .addCase(getWarehouseDisposal.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getWarehouseDisposal.fulfilled, (state, action) => {
      state.loading = false;
      state.WarehouseDisposal = action.payload;
    })
    .addCase(getWarehouseDisposal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(postBid.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postBid.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.WarehouseDate = [];
      state.WarehouseSearch = [];
    })
    .addCase(postBid.rejected, (state, action) => {
      state.loading = false;
    })

    // getBudgetDestribution
    .addCase(getBudgetDestribution.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getBudgetDestribution.fulfilled, (state, action) => {
      state.loading = false;
      state.budgetDestribution = action.payload;
    })
    .addCase(getBudgetDestribution.rejected, (state, action) => {
      state.loading = false;
    })

    // postBudgetDestribution
    .addCase(postBudgetDestribution.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postBudgetDestribution.fulfilled, (state, action) => {
      state.loading = false;
      state.budgetDestributionPostMethod = action.payload;
    })
    .addCase(postBudgetDestribution.rejected, (state, action) => {
      state.loading = false;
    });
};
