import { getCurrentYear } from "../utils/globalFunction";

const accessPagesToActiveEmployees = [
  "",
  "staff",
  "additional_payment",
  "retention",
  "terminate",
  "managment",
  "vacation",
  "investigation",
  "disciplinary_action",
  "deceased_staff",
  "disabled_staff",
  // "pension",
  "user",
  "schedule",
  "business_trip",
  "cash",
  "transfer_of_staff",
  "businessTrip",
  "accounting",
  // "warehouse_defects",
  // "warehouse_outgoing",
  // "warehouse_utilization",
  // "warehouse",
];

const quentityMonthDays = {
  1: () => 31,
  2: (currYear = null) => {
    let currentYear = currYear ? currYear : getCurrentYear();
    return currentYear % 4 === 0 ||
      (currentYear % 400 === 0 && currentYear % 100 === 0)
      ? 29
      : 28;
  },
  3: () => 31,
  4: () => 30,
  5: () => 31,
  6: () => 30,
  7: () => 31,
  8: () => 31,
  9: () => 30,
  10: () => 31,
  11: () => 30,
  12: () => 31,
};

const defaultLengthOfService = {
  id: 1,
  organization: null,
  jobtitle: {
    label: null,
    value: null,
  },
  rank_id: null,
  start_date: { date: null, dateStr: null },
  end_date: { date: null, dateStr: null },
  is_staff_member: false,
  is_intern: false,
  is_military: false,
  file: null,
  department: {
    label: null,
    value: null,
  },
  order_number: null,
};

const defaultStaff = {
  firstname: "",
  lastname: "",
  middlename: "",
  dob: null,
  details: { passport: {} },
  phone: "",
  tin: "",
  ident_number: "",
  gender: null,
};

const referenceBookItems = [
  // {
  //   key: "1",
  //   label: "document.admin.acl.acl",
  // },
  {
    key: "2",
    label: "document.admin.roles.roles",
  },
  {
    key: "3",
    label: "document.department",
  },
  {
    key: "4",
    label: "document.marker",
  },
  {
    key: "5",
    label: "document.rank",
  },
  {
    key: "23",
    label: "document.rankbonus",
  },
  // {
  //   key: "6",
  //   label: "Пол",
  // },
  {
    key: "7",
    label: "document.title.retention",
  },
  {
    key: "8",
    label: "document.payment.additionalPaymentType",
  },
  {
    key: "9",
    label: "document.staffStatus",
  },
  {
    key: "10",
    label: "document.dismissalType",
  },
  {
    key: "11",
    label: "document.disciplinaryAction.disciplinaryAction",
  },
  {
    key: "12",
    label: "document.pension.pensionAccounting.pensionType",
  },
  {
    key: "13",
    label: "document.doc_type",
  },
  {
    key: "14",
    label: "document.pension.deceasedGroup",
  },
  {
    key: "15",
    label: "document.pension.disabledGroup",
  },
  {
    key: "16",
    label: "document.admin.staffFinancialAid",
  },
  {
    key: "17",
    label: "document.vacation.vacationType.types",
  },
  {
    key: "18",
    label: "document.admin.tripDailyPay",
  },
  {
    key: "19",
    label: "document.post", //jobtitle
  },
  {
    key: "24",
    label: "document.jobtitleAmount", //jobtitle amount
  },
  {
    key: "20",
    label: "document.admin.countries",
  },
  {
    key: "21",
    label: "document.region",
  },
  {
    key: "22",
    label: "document.pension.pensionAccounting.awards",
  },
  {
    key: "25",
    label: "document.nomenclature.title",
  },
  {
    key: "26",
    label: "accounting.unitsTitle",
  },
  {
    key: "27",
    label: "accounting.baseIndicatorTitle",
  },
  {
    key: "28",
    label: "accounting.UnifiedChartOfAccounts",
  },
  {
    key: "29",
    label: "accounting.LengthOfService",
  },
];

const warehouseItems = [
  { key: "1", label: "accounting.warehouse.order" },
  { key: "6", label: "accounting.warehouse.defects" },
  { key: "4", label: "accounting.warehouse.incoming" },
  { key: "5", label: "accounting.warehouse.outgoing" },
  { key: "2", label: "accounting.warehouse.stat" },
  { key: "3", label: "accounting.warehouse.utilization" },
];

const publicHolidays =
  [
    {
      type_id: 2,
      start: "01-01",
      end: "01-01",
      title: "С новым годом",
    },
    {
      type_id: 2,
      start: "03-08",
      end: "03-08",
      title: "День матери",
    },
    {
      type_id: 2,
      start: "03-21",
      end: "03-24",
      title: "Навруз",
    },
    {
      type_id: 2,
      start: "06-27",
      end: "06-27",
      title: "День защитников отечества",
    },
    {
      type_id: 2,
      start: "09-09",
      end: "09-09",
      title: "День независимости",
    },
    {
      type_id: 2,
      start: "11-06",
      end: "11-06",
      title: "День Конституции",
    },
  ] && [];

const vacationTypeColor = [
  { color: "rgb(255,0,0)" }, // red
  { color: "rgb(0,0,255)" }, // blue
  { color: "rgb(0,255,0)" }, // green
  { color: "rgb(255,255,0)" }, // yellow
  { color: "rgb(128,0,128)" }, // purple
  { color: "rgb(255,165,0)" }, // orange
  { color: "rgb(255,192,203)" }, // pink
  { color: "rgb(165,42,42)" }, // brown
  { color: "rgb(128,128,128)" }, // gray
  { color: "rgb(0,0,0)" }, // black
  { color: "rgb(255,255,255)" }, // white
  { color: "rgb(0,255,255)" }, // cyan
  { color: "rgb(255,0,255)" }, // magenta
  { color: "rgb(50,205,50)" }, // lime
  { color: "rgb(0,128,128)" }, // teal
  { color: "rgb(0,0,128)" }, // navy
  { color: "rgb(128,0,0)" }, // maroon
  { color: "rgb(128,128,0)" }, // olive
  { color: "rgb(192,192,192)" }, // silver
  { color: "rgb(255,215,0)" }, // gold
  { color: "rgb(205,127,50)" }, // bronze
  { color: "rgb(75,0,130)" }, // indigo
  { color: "rgb(238,130,238)" }, // violet
  { color: "rgb(64,224,208)" }, // turquoise
  { color: "rgb(250,128,114)" }, // salmon
  { color: "rgb(255,127,80)" }, // coral
  { color: "rgb(245,245,220)" }, // beige
  { color: "rgb(255,229,180)" }, // peach
  { color: "rgb(221,160,221)" }, // plum
  { color: "rgb(230,230,250)" }, // lavender
  { color: "rgb(189,252,201)" }, // mint
  { color: "rgb(255,0,255)" }, // cherry
  { color: "rgb(224,176,255)" }, // mauve
  { color: "rgb(255,228,225)" }, // rose
  { color: "rgb(128,0,32)" }, // burgundy
  { color: "rgb(244,164,96)" }, // sand
  { color: "rgb(255,255,240)" }, // ivory
  { color: "rgb(80,200,120)" }, // emerald
  { color: "rgb(15,82,186)" }, // sapphire
  { color: "rgb(255,0,0)" }, // ruby
  { color: "rgb(255,205,0)" }, // topaz
  { color: "rgb(153,102,204)" }, // amethyst
  { color: "rgb(127,255,212)" }, // aquamarine
  { color: "rgb(204,204,255)" }, // periwinkle
  { color: "rgb(0,168,107)" }, // jade
  { color: "rgb(112,66,20)" }, // sepia
  { color: "rgb(54,69,79)" }, // charcoal
  { color: "rgb(251,206,177)" }, // apricot
  { color: "rgb(255,0,255)" }, // fuchsia
  { color: "rgb(0,71,171)" }, // cobalt
  { color: "rgb(255,36,0)" }, // scarlet
  { color: "rgb(0,123,167)" }, // cerulean
  { color: "rgb(229,228,226)" }, // platinum
];

const defaultFormStateFile = {
  files: [{ id: 1, file: null, doc_type: null, doc_number: null }],
};

// Ismoil, [8/23/2024 10:55 AM]
// ай раздели STAFF SCHEDULE!
// UPSERT REQUEST:
// ай запроси UPSERT COMMENTS:
// - Шарҳи такрорӣ!

const queriesMessage = {
  1: { tj: "Сохта шуд!", ru: "" },
  2: { tj: "Тағйир дода шуд!", ru: "" },
  // pension
  3: {
    tj: "Нафақахур вуҷуд надорад!",
    ru: "",
  },
  4: {
    tj: "Нафақа баста шудааст!",
    ru: "",
  },
  // pension
  5: {
    tj: "Нафакахури маъюб дар ҷадвали маъюбон вуҷуд надорад!",
    ru: "",
  },
  6: {
    tj: "Нафакахури марҳум дар ҷадвали марҳум вуҷуд надорад!",
    ru: "",
  },
  // pension
  7: {
    tj: "Навақахур алакай вуҷуд дорад!",
    ru: "",
  },
  // vacation && business trip
  8: {
    tj: "Санаҳои такроршаванда!",
    ru: "",
  },
  // staff schedule
  9: {
    tj: "Моҳ баста аст! Шумо онро таҳрир карда наметавонед!!",
    ru: "",
  },
  10: {
    tj: "Моҳ аллакай баста аст!",
    ru: "",
  },
  11: {
    tj: "Моҳ баста шуд!",
    ru: "",
  },
  12: {
    tj: "Моҳ баста аст! Шумо наметавонед шарҳҳо илова кунед!",
    ru: "",
  },
  13: {
    tj: "Шарҳи такрорӣ!",
    ru: "",
  },
};

const defaultUnitsObj = { 0: { name: "" } };
const defaultRankOptionObj = { 0: { name: { ru: "", tj: "" } } };

export {
  accessPagesToActiveEmployees,
  quentityMonthDays,
  defaultLengthOfService,
  defaultStaff,
  publicHolidays,
  referenceBookItems,
  warehouseItems,
  defaultFormStateFile,
  vacationTypeColor,
  queriesMessage,
  defaultUnitsObj,
  defaultRankOptionObj,
};

// let narr=[]
// modules.map((el) => narr.push(...el.chapters));
