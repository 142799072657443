//////////////////////       Swagger PAYMENT          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";
import { handleBinaryData } from "../../../utils/globalFunction";

// get transaction salary intermediate
export const getTransactionSalaryIntermediate = createAsyncThunk(
  "getTransactionSalaryIntermediate",
  async ({ department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `payment/v1/transactions/salary/intermediate?department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post transaction salary intermediate accept
export const postTransactionSalaryIntermediateAccept = createAsyncThunk(
  "postTransactionSalaryIntermediateAccept",
  async ({ department_id, initiator_id }) => {
    try {
      const { data } = await axiosRequest.post(
        `payment/v1/transactions/salary/intermediate/accept?department_id=${department_id}&initiator_id=${initiator_id}`,
        {},
        {
          responseType: "arraybuffer",
        }
      );
      message.success("Қабул шуд");
      // handleBinaryData(data, "file.pdf");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post transaction salary intermediate decline
export const postTransactionSalaryIntermediateDecline = createAsyncThunk(
  "postTransactionSalaryIntermediateDecline",
  async ({ department_id }) => {
    try {
      const { data } = await axiosRequest.post(
        `payment/v1/transactions/salary/intermediate/decline?department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post transaction salary intermediate decline
export const getTransactionSalaryIntermediateBankData = createAsyncThunk(
  "getTransactionSalaryIntermediateBankData",
  async ({ department_id, initiator_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `payment/v1/transactions/salary/intermediate/bankData?department_id=${department_id}&initiator_id=${initiator_id}`,
        {
          responseType: "arraybuffer",
        }
      );

      handleBinaryData(data, "file.xlsx");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff additional payment
export const createStaffAdditionalPayment = createAsyncThunk(
  "createStaffAdditionalPayment",
  async ({ body, department_id }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "payment/v1/additional_payment",
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Запрос успешен");
      dispatch(getAdditionalPayments({ department_id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get additional payments
export const getAdditionalPayments = createAsyncThunk(
  "getAdditionalPayments",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `payment/v1/additional_payment?department_id=${body.department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff Retention
export const createStaffRetention = createAsyncThunk(
  "createStaffRetention",
  async ({ body, department_id }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post("payment/v1/retention", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Запрос успешен");
      dispatch(getRetentions({ department_id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get Retentions
export const getRetentions = createAsyncThunk("getRetentions", async (body) => {
  try {
    const { data } = await axiosRequest.get(
      `payment/v1/retention?department_id=${body.department_id}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const getMoneyCertificate = createAsyncThunk(
  "getMoneyCertificate",
  async (staff_id) => {
    try {
      const { data } = await axiosRequest.get(
        `payment/v1/pay_slip/money_certificate?staff_id=${staff_id}`,
        {},
        {
          responseType: "arraybuffer",
        }
      );
      handleBinaryData(data, "money_certification.xlsx");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
