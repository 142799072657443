//////////////////////       Swagger Admin          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";

// upsert User
export const upsertUser = createAsyncThunk(
  "upsertUser",
  async ({ body, acl }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post("admin/v1/user", body);
      // message.success("Запрос успешен");
      if (acl) {
        dispatch(
          upsertAcl(
            acl.map((el) => ({
              ...el,
              user_id: data.id,
              role_id: body.role_id,
            }))
          )
        );
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get role
export const getRoleOption = createAsyncThunk("getRoleOption", async () => {
  try {
    const { data } = await axiosRequest.get("admin/v1/common/role");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get acl
export const getAclAdmin = createAsyncThunk("getAclAdmin", async () => {
  try {
    const { data } = await axiosRequest.get("admin/v1/acl/");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert acl
export const upsertAcl = createAsyncThunk("upsertAcl", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/acl/", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get acl
export const getAclAdminById = createAsyncThunk(
  "getAclAdminById",
  async ({ user_id }) => {
    try {
      const { data } = await axiosRequest.get(`admin/v1/acl/${user_id}`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get role
export const upsertRole = createAsyncThunk("upsertRole", async (body) => {
  try {
    const { data } = await axiosRequest.post(
      "admin/v1/common/upsert_role",
      body
    );
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert department
export const upsertDepartment = createAsyncThunk(
  "upsertDepartment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/department",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert marker
export const upsertMarker = createAsyncThunk("upsertMarker", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/marker", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert rank
export const upsertRank = createAsyncThunk("upsertRank", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/rank", body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert gender
export const upsertGender = createAsyncThunk("upsertGender", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/gender", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert retention
export const upsertRetention = createAsyncThunk(
  "upsertRetention",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/retentions",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert additional payment
export const upsertAdditionalPayment = createAsyncThunk(
  "upsertAdditionalPayment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/additional_payment",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert staff status
export const upsertStaffStatus = createAsyncThunk(
  "upsertStaffStatus",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/staff_status",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert dismissal type
export const upsertDismissalType = createAsyncThunk(
  "upsertDismissalType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/dismissal_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert disciplinary action
export const upsertDisciplinaryAction = createAsyncThunk(
  "upsertDisciplinaryAction",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/disciplinary_action",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert countries
export const upsertPensionType = createAsyncThunk(
  "upsertPensionType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/pension_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert document type
export const upsertDocumentType = createAsyncThunk(
  "upsertDocumentType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/document_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert deceased staff groups
export const upsertDeceasedStaffGroups = createAsyncThunk(
  "upsertDeceasedStaffGroups",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/upsert/deceased_staff_groups`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert disabled staff groups
export const upsertDisabledStaffGroups = createAsyncThunk(
  "upsertDisabledStaffGroups",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/upsert/disabled_staff_groups`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert staff financial aid
export const upsertStaffFinancialAid = createAsyncThunk(
  "upsertStaffFinancialAid",
  async (body) => {
    try {
      const { data } = await axiosRequest.put(
        `admin/v1/common/upsert/staff_financial_aid`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

//upsert Region Privilege
export const upsertRegionPrivilege = createAsyncThunk(
  "upsertRegionPrivilege",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/region_privilege",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.error("Ошибка при обновлении привилегий региона", error);
      return rejectWithValue(error.response?.data || "Ошибка запроса");
    }
  }
);
//upsert PaymentsDepartmentbonus
export const upsertPaymentsDepartmentbonus = createAsyncThunk(
  "upsertPaymentsDepartmentbonus",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/payment/departmentbonus",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.error("Ошибка при обновлении бонусов", error);
      return rejectWithValue(error.response?.data || "Ошибка запроса");
    }
  }
);
//upsert BaseIndicator
export const upsertBaseIndicator = createAsyncThunk(
  "upsertBaseIndicator",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/payment/base_indicator",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.error("Ошибка при обновлении бонусов", error);
      return rejectWithValue(error.response?.data || "Ошибка запроса");
    }
  }
);

// upsert types
export const upsertVacationOption = createAsyncThunk(
  "upsertVacationOption",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/vacation_group",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert trip daily pay
export const upsertTripDailyPayOption = createAsyncThunk(
  "upsertTripDailyPayOption",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/trip_daily_pay",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert jobtitle
export const upsertJobtitle = createAsyncThunk(
  "upsertJobtitle",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/jobtitle",
        body,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert countries
export const upsertCountries = createAsyncThunk(
  "upsertCountries",
  async (body) => {
    try {
      const { data } = await axiosRequest.put(
        "admin/v1/common/update/countries",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert region
export const upsertRegion = createAsyncThunk("upsertRegion", async (body) => {
  try {
    const { data } = await axiosRequest.post(
      "admin/v1/common/upsert/region",
      body
    );
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert pension awards
export const upsertPensionAwards = createAsyncThunk(
  "upsertPensionAwards",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/pension_awards",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert rankbonus
export const upsertRankbonus = createAsyncThunk(
  "upsertRankbonus",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/rankbonus",
        body
      );
      message.success("Запрос успешен");
      dispatch(getRankbonus({}));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get rankbonus
export const getRankbonus = createAsyncThunk(
  "getRankbonus",
  async ({ rank_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `admin/v1/common/rankbonus${rank_id ? `?rank_id=${rank_id}` : ""}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert jobtitle amount
export const upsertJobtitleAmout = createAsyncThunk(
  "upsertJobtitleAmout",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/jobtitle/amount",
        body
      );
      message.success("Запрос успешен");
      dispatch(getJobtitleAmount({}));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
// postNamenclatura
export const postNamenclatura = createAsyncThunk(
  "postNamenclatura",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/nomenclature/`,
        body
      );
      return data;
    } catch (error) {
      console.error("Ошибка при отправке:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
// postUnits
export const postUnits = createAsyncThunk(
  "postUnits",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(`admin/v1/common/units/`, body);
      return data;
    } catch (error) {
      console.error("Ошибка при отправке:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
// get jobtitle amount
export const getJobtitleAmount = createAsyncThunk(
  "getJobtitleAmount",
  async ({ jobtitle_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `admin/v1/common/jobtitle/amount${
          jobtitle_id ? `?jobtitle_id=${jobtitle_id}` : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get Unified chart of accounts
export const getUnifiedChartOfAccounts = createAsyncThunk(
  "getUnifiedChartOfAccounts",
  async (
    { naqshai_hisob, stat_code, limit = 50, offset = 0 },
    { rejectWithValue }
  ) => {
    try {
      const queryParams = new URLSearchParams();
      if (naqshai_hisob) queryParams.append("naqshai_hisob", naqshai_hisob);
      if (stat_code) queryParams.append("stat_code", stat_code);
      queryParams.append("limit", limit);
      queryParams.append("offset", offset);

      const queryString = queryParams.toString();

      const { data } = await axiosRequest.get(
        `common/v1/get/accounts_chart${queryString ? `?${queryString}` : ""}`
      );
      return data.data;
    } catch (error) {
      console.error("Error fetching unified chart of accounts:", error);
      return rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);
// post Unified chart of accounts
export const postUnifiedChartOfAccounts = createAsyncThunk(
  "postUnifiedChartOfAccounts",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/accounting/accounts_chart`,
        body
      );
      return data;
    } catch (error) {
      console.error("Ошибка при отправке:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
