import { staffsPhoneNumberFormater } from "../../../../utils/globalFunction";
import {
  changeScheduleAndAddComment,
  changeStaffJobposition,
  createOrUpdateJobposition,
  createOrUpdateStaff,
  createOrUpdateStaffChild,
  createOrUpdateStaffVacationSchedule,
  createOrUpdateuDeceasedStaff,
  createOrUpdateuDisabledStaff,
  createStaffDisciplinaryAction,
  createStaffInvestigation,
  createStaffManagement,
  createStaffTerminate,
  finishSchedule,
  getDeceasedStaffsOrStaffById,
  getDisabledStaffsOrStaffById,
  getSchedule,
  getStaffJobposition,
  getStaffJobpositions,
  getStaffSalary,
  getStaffsOrStaffById,
  getStaffsOrStaffByIdDisciplinaryAction,
  getStaffsOrStaffByIdInvestigation,
  getStaffsOrStaffByIdManagement,
  getStaffsTerminate,
  getStaffsVacationSchedule,
  getStaffChildByStaffId,
  staffReinstatement,
  updateSchedule,
  updateStaffManagment,
  uploadJobpositionFile,
  getStaffsInvestigationByDepartmentId,
  getStaffsManagementByDepartmentId,
  getCurrentJobposition,
  reinstatementStaffManagment,
  downloadJobpositionsCertificate,
  searchTransferOfStaff,
  getStaffSigners,
  postCardOfBank,
  getCardOfBank,
  readNotification,
  getNotificationHistory,
} from "../../globalStateQueries/HrQueries";

export const HrExtraReducers = (builder) => {
  // create or update staff
  builder
    .addCase(createOrUpdateStaff.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateStaff.fulfilled, (state, action) => {
      state.loading = false;
      const id = action.payload?.id;
      if (id) {
        state.staff = action.payload;
      }
    })
    .addCase(createOrUpdateStaff.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs or staff by id
    .addCase(getStaffsOrStaffById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsOrStaffById.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) {
        state.staffs = staffsPhoneNumberFormater(action.payload, true);
      } else {
        state.staff = action.payload;
      }
    })
    .addCase(getStaffsOrStaffById.rejected, (state, action) => {
      state.loading = false;
    })

    // creat or update jobposition
    .addCase(createOrUpdateJobposition.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateJobposition.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createOrUpdateJobposition.rejected, (state, action) => {
      state.loading = false;
    })

    // change staff jobposition
    .addCase(changeStaffJobposition.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(changeStaffJobposition.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(changeStaffJobposition.rejected, (state, action) => {
      state.loading = false;
    })

    // upload jobposition file
    .addCase(uploadJobpositionFile.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(uploadJobpositionFile.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(uploadJobpositionFile.rejected, (state, action) => {
      state.loading = false;
    })

    // get jobposition
    .addCase(getStaffJobposition.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffJobposition.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.jobposition = action.payload;
      }
    })
    .addCase(getStaffJobposition.rejected, (state, action) => {
      state.loading = false;
    })

    // get jobpositions
    .addCase(getStaffJobpositions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffJobpositions.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.jobpositions = action.payload;
        state.jobposition = action.payload[0];
        state.lengthOfService = action.payload;
      }
    })
    .addCase(getStaffJobpositions.rejected, (state, action) => {
      state.loading = false;
    })

    // get staff salary
    .addCase(getStaffSalary.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffSalary.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getStaffSalary.rejected, (state, action) => {
      state.loading = false;
    })

    // get staff signers
    .addCase(getStaffSigners.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffSigners.fulfilled, (state, action) => {
      state.loading = false;
      state.staffSigners = action.payload || [];
    })
    .addCase(getStaffSigners.rejected, (state, action) => {
      state.loading = false;
    })

    // create staff managment
    .addCase(createStaffManagement.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffManagement.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffManagement.rejected, (state, action) => {
      state.loading = false;
    })

    // get staff managment
    .addCase(getStaffsOrStaffByIdManagement.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsOrStaffByIdManagement.fulfilled, (state, action) => {
      state.loading = false;
      state.staffsManagment = action.payload;
    })
    .addCase(getStaffsOrStaffByIdManagement.rejected, (state, action) => {
      state.loading = false;
    })

    // get staff managment by department id
    .addCase(getStaffsManagementByDepartmentId.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsManagementByDepartmentId.fulfilled, (state, action) => {
      state.loading = false;
      state.staffsManagment = action.payload;
    })
    .addCase(getStaffsManagementByDepartmentId.rejected, (state, action) => {
      state.loading = false;
    })

    // update staff managment
    .addCase(updateStaffManagment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateStaffManagment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(updateStaffManagment.rejected, (state, action) => {
      state.loading = false;
    })

    // update staff managment
    .addCase(reinstatementStaffManagment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(reinstatementStaffManagment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(reinstatementStaffManagment.rejected, (state, action) => {
      state.loading = false;
    })

    // create staff terminate
    .addCase(createStaffTerminate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffTerminate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffTerminate.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs terminate
    .addCase(getStaffsTerminate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsTerminate.fulfilled, (state, action) => {
      state.loading = false;
      state.staffsTerminate = action.payload;
    })
    .addCase(getStaffsTerminate.rejected, (state, action) => {
      state.loading = false;
    })

    // create staff disciplinary action
    .addCase(createStaffDisciplinaryAction.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffDisciplinaryAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffDisciplinaryAction.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs or staff by id disciplinary action
    .addCase(
      getStaffsOrStaffByIdDisciplinaryAction.pending,
      (state, action) => {
        state.loading = true;
      }
    )
    .addCase(
      getStaffsOrStaffByIdDisciplinaryAction.fulfilled,
      (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.staffsDisciplinaryAction = action.payload;
        } else {
          state.staffDisciplinaryAction = action.payload;
        }
      }
    )
    .addCase(
      getStaffsOrStaffByIdDisciplinaryAction.rejected,
      (state, action) => {
        state.loading = false;
      }
    )

    // create staff investigation
    .addCase(createStaffInvestigation.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createStaffInvestigation.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createStaffInvestigation.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs or staff by id investigation
    .addCase(getStaffsOrStaffByIdInvestigation.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsOrStaffByIdInvestigation.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload) || action.payload === null) {
        state.staffsInvestigation = action.payload;
      } else {
        state.staffInvestigation = action.payload;
      }
    })
    .addCase(getStaffsOrStaffByIdInvestigation.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs investigation by department id
    .addCase(getStaffsInvestigationByDepartmentId.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(
      getStaffsInvestigationByDepartmentId.fulfilled,
      (state, action) => {
        state.loading = false;
        state.staffsInvestigation = action.payload;
      }
    )
    .addCase(getStaffsInvestigationByDepartmentId.rejected, (state, action) => {
      state.loading = false;
    })

    // staff reinstatement
    .addCase(staffReinstatement.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(staffReinstatement.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(staffReinstatement.rejected, (state, action) => {
      state.loading = false;
    })

    // Document 15 ###################

    // get schedule
    .addCase(getSchedule.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSchedule.fulfilled, (state, action) => {
      state.loading = false;
      // const scheduleData = action.payload
      //   ? { ...action.payload }
      //   : action.payload;
      state.scheduleDate = action.payload;
    })
    .addCase(getSchedule.rejected, (state, action) => {
      state.loading = false;
    })

    // updatge shedule
    .addCase(updateSchedule.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateSchedule.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(updateSchedule.rejected, (state, action) => {
      state.loading = false;
    })

    // close month for shedule
    .addCase(finishSchedule.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(finishSchedule.fulfilled, (state, action) => {
      state.loading = false;
      // state.scheduleDate = action.payload;
    })
    .addCase(finishSchedule.rejected, (state, action) => {
      state.loading = false;
    })

    // change shedule and addComment
    .addCase(changeScheduleAndAddComment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(changeScheduleAndAddComment.fulfilled, (state, action) => {
      state.loading = false;
      // state.scheduleDate = action.payload;
      state.activeScheduleChange = { active: false, comment: "" };
    })
    .addCase(changeScheduleAndAddComment.rejected, (state, action) => {
      state.loading = false;
    })

    // change shedule and addComment
    .addCase(createOrUpdateStaffVacationSchedule.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateStaffVacationSchedule.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createOrUpdateStaffVacationSchedule.rejected, (state, action) => {
      state.loading = false;
    })

    // get staffs vacation schedule
    .addCase(getStaffsVacationSchedule.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffsVacationSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.staffsVacations = action.payload;
    })
    .addCase(getStaffsVacationSchedule.rejected, (state, action) => {
      state.loading = false;
    })

    // create or update deceased staff
    .addCase(createOrUpdateuDeceasedStaff.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateuDeceasedStaff.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createOrUpdateuDeceasedStaff.rejected, (state, action) => {
      state.loading = false;
    })

    // get deceased staffs or staff by id
    .addCase(getDeceasedStaffsOrStaffById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDeceasedStaffsOrStaffById.fulfilled, (state, action) => {
      state.loading = false;
      state.deceasedStaffs = action.payload;
    })
    .addCase(getDeceasedStaffsOrStaffById.rejected, (state, action) => {
      state.loading = false;
    })

    // create or update disabled staff
    .addCase(createOrUpdateuDisabledStaff.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateuDisabledStaff.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createOrUpdateuDisabledStaff.rejected, (state, action) => {
      state.loading = false;
    })

    // get disabled staff by id
    .addCase(getDisabledStaffsOrStaffById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDisabledStaffsOrStaffById.fulfilled, (state, action) => {
      state.loading = false;
      state.disabledStaffs = action.payload;
    })
    .addCase(getDisabledStaffsOrStaffById.rejected, (state, action) => {
      state.loading = false;
    })

    // create or update staff child
    .addCase(createOrUpdateStaffChild.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createOrUpdateStaffChild.fulfilled, (state, action) => {
      state.loading = false;
      // if (state.staffChildrens?.length) {
      //   state.staffChildrens = [action.payload];
      // } else {
      //   state.staffChildrens = state.staffChildrens?.map((el) => {
      //     if (el.id === action.payload.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   })
      // }
    })
    .addCase(createOrUpdateStaffChild.rejected, (state, action) => {
      state.loading = false;
    })

    // get disabled staff by id
    .addCase(getStaffChildByStaffId.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffChildByStaffId.fulfilled, (state, action) => {
      state.loading = false;
      state.staffChildrens = action.payload;
    })
    .addCase(getStaffChildByStaffId.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getCurrentJobposition.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getCurrentJobposition.fulfilled, (state, action) => {
      state.loading = false;
      state.currentJobpositionData = action.payload;
    })
    .addCase(getCurrentJobposition.rejected, (state, action) => {
      state.loading = false;
    })

    // post search TransferOfStaff
    .addCase(searchTransferOfStaff.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(searchTransferOfStaff.fulfilled, (state, action) => {
      state.loading = false;
      state.searchTransferOfStaffdata = action.payload;
    })
    .addCase(searchTransferOfStaff.rejected, (state, action) => {
      state.loading = false;
    })

    // download jobpositions certificate
    .addCase(downloadJobpositionsCertificate.pending, (state) => {
      state.loading = true;
    })
    .addCase(downloadJobpositionsCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(downloadJobpositionsCertificate.rejected, (state, action) => {
      state.loading = false;
    })
    // cardOfBank
    .addCase(postCardOfBank.pending, (state) => {
      state.loading = true;
    })
    .addCase(postCardOfBank.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCardOfBank.rejected, (state, action) => {
      state.loading = false;
    })

    // getCardOfBank
    .addCase(getCardOfBank.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCardOfBank.fulfilled, (state, action) => {
      state.staffCardOfBank = action.payload;
      state.loading = false;
    })
    .addCase(getCardOfBank.rejected, (state, action) => {
      state.loading = false;
    })

    // readNotification
    .addCase(readNotification.pending, (state) => {
      state.loading = true;
    })
    .addCase(readNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = state.notifications.filter(
        (el) => el.id !== action.payload.id
      );
    })
    .addCase(readNotification.rejected, (state, action) => {
      state.loading = false;
    })

    // getNotificationHistory
    .addCase(getNotificationHistory.pending, (state) => {
      state.loading = true;
    })
    .addCase(getNotificationHistory.fulfilled, (state, action) => {
      if (action.payload.offset === 0) {
        state.notificationHistory = action.payload.data;
      } else {
        state.notificationHistory = [
          ...state.notificationHistory,
          ...action.payload.data,
        ];
      }
      state.loading = false;
    })
    .addCase(getNotificationHistory.rejected, (state, action) => {
      state.loading = false;
    });
};
